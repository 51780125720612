import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useWebsitesBulletQuery } from "../../../hooks/useWebsitesBulletQuery"
import { Wrapper, StyledImg } from "./WebsitesBullet.styles"

const Bullet = () => {
  
  const {
    wpPage: { websites: data },
  } = useWebsitesBulletQuery()

  const bulletText = data.bulletText
  const imageData = getImage(data.bulletImage.localFile)
  const imageAlt = data.bulletImage.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <StyledImg image={imageData} alt={imageAlt} />
            </div>
            <div className="column">
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: bulletText }} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Bullet
