import { useStaticQuery, graphql } from "gatsby"

export const useWebsitesEaseQuery = () => {
  const data = useStaticQuery(graphql`
    query WebsitesEaseQuery {
        wpPage(databaseId: {eq: 195}) {
            id
            websites {
              easeText
              }
            }
          }
  `)

  return data
}
