import { useStaticQuery, graphql } from "gatsby"

export const useWebsiteServiceQuery = () => {
  const data = useStaticQuery(graphql`
    query WebsiteServiceQuery {
        wpPage(databaseId: {eq: 185}) {
            id
            ACF_Homepage {
              websiteServices
              }
            }
          }
  `)

  return data
}
