import React from "react"
import { useWebsitesEaseQuery } from "../../../hooks/useWebsitesEaseQuery"
import { Wrapper } from "./WebsitesEase.styles"

const WebsitesEase = () => {
    
  const {
    wpPage: { websites: data },
  } = useWebsitesEaseQuery()

  const easeText = data.easeText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div dangerouslySetInnerHTML={{ __html: easeText }} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WebsitesEase
