import React from 'react';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import WebsitesHero from '../../components/Websites/WebsitesHero/WebsitesHero';
import WebsitesIntro from '../../components/Websites/WebsitesIntro/WebsitesIntro';
import Bullet from '../../components/Websites/WebsitesBullet/WebsitesBullet';
import WebsitesEase from '../../components/Websites/WebsitesEase/WebsitesEase';
import WebsitesOne from '../../components/Websites/WebsitesOne/WebsitesOne'
import WebsitesTwo from '../../components/Websites/WebsitesTwo/WebsitesTwo';
import LetsChat from '../../components/Home/LetsChat/LetsChat';
import WebsiteServices from '../../components/Home/WebsiteServices/WebsiteServices';
import WebTrends from '../../components/Websites/WebsitesWebTrends/WebsitesWebTrends';
import OurProcess from '../../components/Websites/WebsitesOurProcess/WebsitesOurProcess';

const WebsitesPage = () => (
  <Layout>
    <Seo title="Websites" />
    <WebsitesHero />
    <WebsitesIntro />
    <Bullet />
    <OurProcess />
    <WebsitesEase />
    <WebsitesOne />
    <WebsitesTwo />
    <WebTrends />
    <WebsiteServices />
    <LetsChat />
  </Layout>
)


export default WebsitesPage;
