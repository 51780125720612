import { useStaticQuery, graphql } from "gatsby"

export const useOneQuery = () => {
  const data = useStaticQuery(graphql`
    query OneQuery {
      wpPage(databaseId: {eq: 185}) {
        id
        ACF_Homepage {
          oneImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
              }
            }
          }
          oneLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 365, placeholder: BLURRED)
              }
            }
          }
          oneText
          oneList {
            listItem
          }
        }
      }
    }
  `)

  return data
}
