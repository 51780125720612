import { useStaticQuery, graphql } from "gatsby"

export const useCs2Query = () => {
  const data = useStaticQuery(graphql`
    query Cs2Query {
      wpPage(databaseId: {eq: 195}) {
        id
        websites {
          cs2Image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
              }
            }
          }
          cs2Logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 365, placeholder: BLURRED)
              }
            }
          }
          cs2Text
          cs2List {
            listItem
          }
        }
      }
    }
  `)

  return data
}