import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useWebsitesIntroQuery } from "../../../hooks/useWebsitesIntroQuery"
import { Wrapper, StyledImg } from "./WebsitesIntro.styles"

const WebsitesIntro = () => {
  
  const {
    wpPage: { websites: data },
  } = useWebsitesIntroQuery()

  const introText = data.introText
  const imageData = getImage(data.introImage.localFile)
  
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
            <div className="column">
                <StyledImg image={imageData} alt="Design your dream website" />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WebsitesIntro
