import { useStaticQuery, graphql } from "gatsby"

export const useWtQuery = () => {
  const data = useStaticQuery(graphql`
    query WtQuery {
      wpPage(databaseId: {eq: 195}) {
        id
        websites {
          wtImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            altText
          }
          wtButton {
            url
            title
            target
          }
          wtText
        }
      }
    }
  `)

  return data
}
