import React from "react"
import { Wrapper } from "./WebsiteServices.styles"
import { useWebsiteServiceQuery } from "../../../hooks/useWebsiteServiceQuery"

const WebsiteServices = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useWebsiteServiceQuery()
  const websiteServices = data.websiteServices
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
        <div className="website-services-text" dangerouslySetInnerHTML={{ __html: websiteServices }} />
        </div>
      </div>
    </Wrapper>
  )
}

export default WebsiteServices
