import { useStaticQuery, graphql } from "gatsby"

export const useWebsitesIntroQuery = () => {
  const data = useStaticQuery(graphql`
    query WebsitesIntroQuery {
        wpPage(databaseId: {eq: 195}) {
            id
            websites {
              introText
              introImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
