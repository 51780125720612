import { useStaticQuery, graphql } from "gatsby"

export const useWebsitesHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query WebsitesHeroQuery {
      wpPage(databaseId: {eq: 195}) {
        id
        websites {
          hero {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          heroMask {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data
}
