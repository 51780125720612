import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useWebsitesHeroQuery } from "../../../hooks/useWebsitesHeroQuery"
import { Wrapper, StyledImg } from "./WebsitesHero.styles"

const WebsitesHero = () => {
  
  const {
    wpPage: { websites: data },
  } = useWebsitesHeroQuery()

  const imageData = getImage(data.hero.localFile)
  const heroAltText = data.hero.altText

  return (
    <Wrapper>
      <div className="container">
        <StyledImg image={imageData} alt={heroAltText} />
      </div>
    </Wrapper>
  )
}

export default WebsitesHero