import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 475px;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 8rem 0 6.5rem;
  background: #42E2B8;
  color: #000;
  @media screen and (max-width: 991px) {
        padding: 4rem 0 2.5rem;
    }
  .container{
    .columns{
        width: calc(100% + 0.75rem);
        align-items: center;
        max-width: 75%;
        margin: auto;
        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
        }
        .column{
            :nth-child(1) {
                h1 {
                    line-height: 1em;
                    margin-bottom: 0;
                    color: #000;
                }
                text-align: center;
            }
            :nth-child(2) {
                max-width: 46%;
                padding-right: 0;
                @media screen and (max-width: 991px) {
                    max-width: 100%;
                    width: 100%;
                }
                img {
                    padding-left: 15%;
                    width: 100%;
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                }
            }
        }
        p {
            font-size: 18px;
            color: #000;
            line-height: 1.6em;
            max-width: 590px;
            margin-left: auto;
            margin-right: auto;
        }
    }
  }
`
