import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  > .container {
    padding: 7rem 0 7rem;
    @media screen and (max-width:767px) {
        padding: 0 0 2rem;
    }
  }
  .columns {
    align-items: center;
    @media screen and (max-width:767px) {
        display: block;
        margin: 0;
      }
    .column{
      :nth-child(1) {
        max-width: 37%;
        @media screen and (max-width:767px) {
          padding: 0;
          max-width: 100%;
        }
      }
      :nth-child(2) {
        max-width: 50%;
        margin-left: auto;
        padding-left: 8vw;
        @media screen and (max-width:767px) {
          padding: 1.5rem 0.75rem;
          max-width: 100%;
        }
        .content-wrapper {
          max-width: 440px;
        }
      }
    }
  }
  img {
    object-fit: cover;
    @media screen and (max-width:767px) {
    }
  }
  p{
    font-size: 18px;
    line-height: 1.6em;
  }
`