import React from "react"
import { Wrapper, StyledImg } from "./WebsitesOurProcess.styles"
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import { useOpQuery } from "../../../hooks/useWebsitesOurProcessQuery";

const OurProcess = () => {

  const {
    wpPage: { websites: data },
  } = useOpQuery()

  const imageData = getImage(data.opImage.localFile)
  const opImageAltText = data.opImage.altText
  const opText = data.opText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
          <div className="column">
            <StyledImg image={imageData} 
            alt={opImageAltText}
            />
          </div>
          <div className="column">
            <div className="op-text" dangerouslySetInnerHTML={{ __html: opText }} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default OurProcess
