import React from "react"
import { Wrapper, StyledImg } from "./WebsitesTwo.styles"
import { getImage } from "gatsby-plugin-image"
import { useCs2Query } from "../../../hooks/useWebsitesTwoQuery"
import { motion } from "framer-motion";

const WebsitesTwo = () => {

  const {
    wpPage: { websites: data },
  } = useCs2Query()

  const cs2Logo = getImage(data.cs2Logo.localFile)
  const imageData = getImage(data.cs2Image.localFile)
  const cs2LogoAltText = data.cs2Logo.altText
  const cs2ImageAltText = data.cs2Image.altText
  const cs2Text = data.cs2Text

  return (

    <Wrapper>
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="heading">
              <StyledImg 
                image={cs2Logo} 
                alt={cs2LogoAltText} 
                objectFit="contain"
              />
            </div>
            <div className="text-section">
            <div className="cs2-text" dangerouslySetInnerHTML={{ __html: cs2Text }} />
            <ul>
              {data.cs2List.map( (description, i) => (
                  <li key={i}>{description.listItem}</li>
              ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="color-mask">
            <motion.div className="color-mask-layer" 
            //initial={{ x: 600 }} 
            // animate={{ x: 0 }} 
            // transition={spring}
            //whileInView={{ x: 0 }}
            initial={{ transform: 'scaleX(0.5)' }}
            whileInView={{ transform: 'scaleX(1)' }}

            />
            <StyledImg image={imageData} alt={cs2ImageAltText} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WebsitesTwo
