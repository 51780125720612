import { useStaticQuery, graphql } from "gatsby"

export const useOpQuery = () => {
    const data = useStaticQuery(graphql`
    query OpQuery {
      wpPage(databaseId: {eq: 195}) {
        id
        websites {
          opImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          opText
        }
      }
    }
  `)

  return data
}
