import { useWebsitesHeroQuery } from "../../../hooks/useWebsitesHeroQuery"

const WebsiteHeroMask = () => {
  
  const {
    wpPage: { websites: data },
  } = useWebsitesHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default WebsiteHeroMask
