import { useStaticQuery, graphql } from "gatsby"

export const useWebsitesBulletQuery = () => {
  const data = useStaticQuery(graphql`
    query WebsitesBulletQuery {
        wpPage(databaseId: {eq: 195}) {
            id
            websites {
              bulletText
              bulletImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
