import React from "react"
import { Link } from "gatsby";
import { Wrapper, StyledImg } from "./WebsitesWebTrends.styles"
import { getImage } from "gatsby-plugin-image"
import { useWtQuery } from "../../../hooks/useWebsiteWebTrendsQuery"

const WebTrends = () => {

  const {
    wpPage: { websites: data },
  } = useWtQuery()

  const imageData = getImage(data.wtImage.localFile)
  const wtImageAltText = data.wtImage.altText
  const wtText = data.wtText
  const wtButtonTitle = data.wtButton.title
  const wtButtonUrl = data.wtButton.url
  return (
    <Wrapper>
      <div id="web-trends"className="container">
        <div className="columns">
          <div className="column">
          <StyledImg image={imageData} 
          alt={wtImageAltText}
          />
          </div>
          <div className="column">
            <div className="content-wrapper">
              <div className="chat-text" dangerouslySetInnerHTML={{ __html: wtText }} />
              <Link to={wtButtonUrl} className="btn">
                <span dangerouslySetInnerHTML={{ __html: wtButtonTitle }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WebTrends
